import { useContext, useEffect, useState } from "react";

import Graph from "../components/graph/Graph";
import Filter from "../components/filters/Filter";

import { Tabs, Tab } from "@appkit4/react-components/tabs";
import ListView from "../components/list/ListView";
import MetadataCard from "../components/metadataCard/MetadataCard";

import { StoreContext } from "../App";
import { IRootStore } from "../store/RootStore";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  DropdownButton,
  Footer,
  Header,
  HeaderOptionItem,
} from "@appkit4/react-components";
import SearchInput from "../components/filters/SearchInput";
import { useSearchParams } from "react-router-dom";
import { logoutUser } from "../services/Token";
import { MainContentView } from "../@types/enums";
import Spinner from "../components/Spinner";

const MainContainer = observer(() => {
  const [searchParams] = useSearchParams();
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const store = useContext<IRootStore>(StoreContext);

  useEffect(() => {
    async function getData(){
      const courtCase = searchParams.get("name");
      
      if (courtCase) {
        await store.courtCaseStore.getOneCourtCaseAndRelations(courtCase);
      } else {
        await store.courtCaseStore.getAllCourtCasesAndRelations(true);
      }
    }
    getData();

  }, [searchParams, store.courtCaseStore]);

  const footerContent =
    "© 2022 PwC. All rights reserved. PwC refers to the Finnish member firm of the PwC network or one of its subsidiaries or affiliates.";
  const footerType = "links";
  const footerLinks = [
    { name: "Privacy policy", href: "#" },
    { name: "Terms and conditions", href: "#" },
    { name: "Cookie notice", href: "#" },
  ];

  function onTabChange(i: number) {
    store.setActiveView(i);
  }

  function handleUserAvatarDropdownSelect(value: string | number) {
    if (value.toString() === "logout") {
      handleUserClickedLogout();
    }
  }

  function handleUserClickedLogout() {
    setIsLoggingOut(true);
    logoutUser();
  }

  function renderGraph() {
    if (store.getActiveView() === MainContentView.graph) {
      return <Graph />;
    }
  }

  function renderList() {
    if (store.getActiveView() === MainContentView.list) {
      return <ListView />;
    }
  }

  function renderMetadataCard() {
    if (
      store.courtCaseStore.selectedCourtCase ||
      store.courtCaseStore.selectedRelation
    ) {
      return (
        <MetadataCard
          courtCase={store.courtCaseStore.selectedCourtCase}
          relation={store.courtCaseStore.selectedRelation}
        />
      );
    }
  }
  function renderUserAvatar() {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <DropdownButton
          customTriggerNode={true}
          customTriggerClassName="user-avatar"
          splitButton={false}
          onSelect={(value: string | number) =>
            handleUserAvatarDropdownSelect(value)
          }
          data={[
            {
              value: "logout",
              label: "Logout",
              iconName: "lockclosed-locked-outline",
            },
          ]}
          prefixTemplate={(item) => (
            <span className={`Appkit4-icon icon-${item.iconName}`}></span>
          )}
        >
          <Avatar
            disabled={false}
            label={store.getUserInitials()}
            ariaLabel="settings menu"
          />
        </DropdownButton>
      </div>
    );
  }

  if (isLoggingOut) return <Spinner loadingText="Logging you out..." />;


  return (
    <div className="nav-template template-theme">
      <Header
        type="transparent"
        onClickLogo={undefined}
        titleTemplate={() => "Court Case Network"}
        contentTemplate={() => <SearchInput />}
        optionsTemplate={() => {
          return (
            <>
              <HeaderOptionItem
                iconName="help-question-outline"
                label="Support"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_HELPFILE_URL}`,
                    "_blank",
                    "noreferrer"
                  )
                }
              ></HeaderOptionItem>
            </>
          );
        }}
        userTemplate={() => renderUserAvatar()}
      ></Header>
      <div className="a-right-content">
        <div className="floating-menu-container">
          <div style={{ height: "100%" }}>
            <Tabs
              className="view-selector"
              type="filled"
              activeIndex={store.getActiveView()}
              onTabChange={onTabChange}
            >
              <Tab label="Network" icon="icon-network-outline"></Tab>
              <Tab label="List" icon="icon-list-view-outline"></Tab>
            </Tabs>
            <Filter />
          </div>
        </div>
        {renderList()}
        {renderGraph()}
        {renderMetadataCard()}
      </div>
      <div className="footer">
        <Footer
          content={footerContent}
          type={footerType}
          links={footerLinks}
        ></Footer>
      </div>
    </div>
  );
});

export default MainContainer;
